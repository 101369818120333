import { useCallback, useContext, useEffect, useRef } from 'react'
import cn from 'classnames'
import get from 'lodash/get'
import { span, theme } from '../../styles/theme'
import useSnapshot from '../../store/useSnapshot'
import HeaderNav from './HeaderNav'
import Section from '../Section'
import Link from '../Link'
import Overlay from '../Overlay'
import { isMenuOpen, closeMenu, isHomepageAnimatedIn, getDialogData, isMenuLoaded } from '../../store/layoutSlice'
import { getIndex as getCollectionIndex } from '../../store/productCollectionSlice'

import Logo from '../../icons/logo.svg'
import { createUseStyles } from '../../helpers/createStyles'
import { SmoothScrollContext, useScrollListener } from '../SmoothScrollbar/useSmoothScrollbar'
import { primaryInput } from 'detect-it'
import gsap from 'gsap'
import useWindowResize from '../../hooks/useWindowResize'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import use100vhStyle from '../../hooks/use100vh'
import forEach from 'lodash/forEach'
import toArray from 'lodash/toArray'

const useChangeColorCallback = (elementRef, duration = 0.5) => {
  const currentColor = useRef({ foreground: theme.colors.black })
  const targetColor = useRef(theme.colors.black)
  return useCallback((color) => {
    if (currentColor && color) {
      if (targetColor.current !== color) {
        targetColor.current = color
        gsap.to(currentColor.current, {
          foreground: color,
          duration,
          ease: 'sine.inOut',
          onUpdate: () => {
            elementRef.current?.style.setProperty('--foreground', currentColor.current.foreground)
          }
        })
      }
    }
  }, [elementRef, duration])
}

const Header = ({ page, topBannerRef }) => {
  const { slug, _type } = page
  const isHome = slug === 'home'
  const isExploreAgra = slug === 'explore-agra'
  const isSubscribePage = slug === 'subscribe'
  const isErrorPage = _type === 'errorPage'
  const isLandingPage = get(page, ['pageType']) === 'landing-page'
  const isCollectionPage = get(page, ['slices', 0, '_type']) === 'product_collections'
  const hasTransparentHeader = get(page, ['hasTransparentHeader'])
  const hideGradientBar = get(page, ['hideGradientBar'])
  const snap = useSnapshot()
  const dialog = getDialogData(snap)
  const headerRef = useRef()
  const navRef = useRef()
  const styles = useStyles()
  const scrollContext = useContext(SmoothScrollContext)
  const windowHeight = use100vhStyle()
  const collectionIndex = getCollectionIndex(snap)
  const logoRef = useRef()
  const backgroundRef = useRef()
  const gradientRef = useRef()
  const animatedIn = isHomepageAnimatedIn(snap)
  const menuLoaded = isMenuLoaded(snap)
  const offsetItemsRef = useRef([])

  const menuOpen = isMenuOpen(snap)
  const localsRef = useRef({ isHome, currentHeaderOffset: 0, shrinkHeader: false })

  const changeColor = useChangeColorCallback(headerRef)

  const handleDialogClick = useCallback(() => {
    closeMenu()
  }, [menuOpen])

  useEffect(() => {
    offsetItemsRef.current = [
      ...toArray(document.querySelectorAll('.menu-item-title')),
      ...toArray(document.querySelectorAll('.dialog-header'))
    ]
  }, [page, dialog, menuLoaded])

  const positionHeader = useCallback(() => {
    const y = scrollContext.current.getScrollY()
    const { currentHeaderOffset, headerOffset, shrinkHeader, topBannerHeight } = localsRef.current
    // const getOffsetItems = () => ([
    //   ...toArray(document.querySelectorAll('.menu-item-title')),
    //   ...toArray(document.querySelectorAll('.dialog-header'))
    // ])
    if (headerRef.current && navRef.current) {
      if (primaryInput !== 'touch') {
        if (y <= 0 && shrinkHeader) {
          localsRef.current.shrinkHeader = false
          gsap.to(localsRef.current, { currentHeaderOffset: 0, duration: 0.8, ease: 'power2.out' })
        }
        const shrinkOffset = isHome ? windowHeight - 110 - topBannerHeight : 40
        if (y > shrinkOffset && !shrinkHeader) {
          localsRef.current.shrinkHeader = true
          gsap.to(localsRef.current, { currentHeaderOffset: headerOffset, duration: 0.6, ease: 'power2.out' })
        }

        const yOffset = y - currentHeaderOffset
        headerRef.current.style.transform = `translate3d(0, ${yOffset}px, 0)`
        backgroundRef.current.style.transform = `translate3d(0, ${yOffset}px, 0)`

        if (!hideGradientBar) {
          gradientRef.current.style.transform = `translate3d(0, ${yOffset}px, 0)`
        }

        // But hacky but works well, it will just move the menu items titles so they align with the header
        forEach(offsetItemsRef.current, item => {
          item.style.transform = `translate3d(0, -${localsRef.current.isMobile ? topBannerHeight : currentHeaderOffset}px, 0)`
        })
      } else {
        // Should be move the header on mobile
      }
    }
    return () => {
      if (headerRef.current && navRef.current) {
        headerRef.current.style.transform = 'translate3d(0, 0, 0)'
        backgroundRef.current.style.transform = 'translate3d(0, 0, 0)'
        if (!hideGradientBar) {
          gradientRef.current.style.transform = 'translate3d(0, 0, 0)'
        }
        forEach(offsetItemsRef.current, item => {
          item.style.transform = 'translate3d(0, 0, 0)'
        })
      }
    }
  }, [isHome, windowHeight, scrollContext])

  const setHeaderColors = useCallback(() => {
    const { navHeight } = localsRef.current
    const y = scrollContext.current.getScrollY()
    const fillHeader = (fill) => {
      if (backgroundRef.current) {
        backgroundRef.current.style.pointerEvents = fill ? 'all' : 'none'
        backgroundRef.current.style.opacity = fill ? 1 : 0
        if (!hideGradientBar) {
          gradientRef.current.style.opacity = fill ? 1 : 0
        }
      }
    }
    if (isHome) {
      const { headerHeight } = localsRef.current
      const lightMode = !y || y < (windowHeight - (navHeight + headerHeight + theme.spacing(3)))
      const headerFilled = y >= windowHeight
      changeColor(lightMode ? theme.colors.white : theme.colors.black)
      fillHeader(headerFilled)
    } else if (isCollectionPage) {
      changeColor(collectionIndex > 0 ? theme.colors.white : theme.colors.black)
      fillHeader(localsRef.current.isMobile || collectionIndex === -1)
    } else if (isErrorPage || isLandingPage || isExploreAgra) {
      const lightMode = !y || y < windowHeight
      changeColor(lightMode ? theme.colors.white : theme.colors.black)
      fillHeader(!lightMode)
    } else if (hasTransparentHeader) {
      changeColor(theme.colors.white)
      fillHeader(false)
    } else {
      changeColor(theme.colors.black)
      fillHeader(true)
    }
  }, [isHome, isErrorPage, isCollectionPage, windowHeight, isSubscribePage, collectionIndex])

  // I need to run this on the animation frame as well as we are now animating the y position
  // using a easing when we shrink and grow the header.
  useEffect(() => {
    gsap.ticker.add(positionHeader)
    return () => {
      gsap.ticker.remove(positionHeader)
    }
  }, [positionHeader])

  const storeLayout = () => {
    if (navRef.current) {
      localsRef.current = {
        ...localsRef.current,
        navHeight: navRef.current.clientHeight,
        navOffset: navRef.current.parentNode.offsetTop,
        headerHeight: headerRef.current.clientHeight,
        topBannerHeight: topBannerRef?.current?.clientHeight || 0,
        headerOffset: window.innerWidth < theme.breakpoints.values.md ? 0 : 40,
        isMobile: window.innerWidth < theme.breakpoints.values.md
      }
      positionHeader()
      setHeaderColors()
    }
  }

  useWindowResize(useDebouncedCallback(storeLayout, 200))
  useEffect(storeLayout, [])

  useScrollListener(useCallback(() => {
    positionHeader()
    setHeaderColors()
  }), true, false)

  useEffect(() => {
    gsap.to(navRef.current, { opacity: 1, duration: 1, ease: 'sine.inOut', delay: localsRef.current.isHome ? 3.5 : 0 })
    gsap.to(logoRef.current, { opacity: 1, duration: 1, ease: 'sine.inOut', delay: 0.1 })
  }, [])

  return (
    <>
      <div className={styles.background} ref={backgroundRef} />
      {!hideGradientBar && (
        <div className={styles.gradient} ref={gradientRef} />
      )}
      <header className={styles.header} ref={headerRef} id='header'>
        <Overlay
          className={styles.overlay}
          show={menuOpen}
          onClick={handleDialogClick}
          zIndex={theme.zIndex.dialog - 1}
        />
        <Section tag='nav' noBottomMargin fullWidth className={styles.nav}>
          <Section className={styles.container} noBottomMargin grid>
            <div className={styles.logo} ref={logoRef}>
              <Link className={styles.logoLink} to='/' aria-label='Armadillo'>
                <Logo className={cn(styles.logoSvg, { home: isHome && !animatedIn })} id='logo' />
              </Link>
            </div>
            <div className={styles.rhs} ref={navRef}>
              <HeaderNav />
            </div>
          </Section>
        </Section>
      </header>
    </>
  )
}

const useStyles = createUseStyles({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.header,
    pointerEvents: 'none',
    height: `${theme.headerSize.sm}px`,
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    transition: 'color 0.5s ease-in.out',
    '&.light': {
      '--foreground': theme.colors.white
    },
    [theme.breakpoints.up('md')]: {
      height: `${theme.headerSize.md}px`
    }
  },
  overlay: {
    zIndex: theme.zIndex.header,
    height: '100vh',
    backgroundColor: 'transparent!important'
  },
  container: {},
  nav: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0,
    zIndex: theme.zIndex.headerBackground,
    pointerEvents: 'none',
    backgroundColor: theme.colors.background,
    height: 80,
    [theme.breakpoints.up('md')]: {
      height: 108
    }
  },
  gradient: {
    position: 'fixed',
    left: 0,
    right: 0,
    opacity: 0,
    zIndex: 1,
    pointerEvents: 'none',
    background: `linear-gradient(180deg, ${theme.colors.background} 0%, ${theme.colors.transparentBackground} 100%)`,
    top: 80,
    height: 40,
    [theme.breakpoints.up('md')]: {
      top: 108,
      height: 80
    }

  },
  logo: {
    display: 'flex',
    pointerEvents: 'all',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gridColumn: '1 / span 4',
    width: '104px',
    opacity: 0,
    '& svg': {
      display: 'block',
      width: '104px'
    },
    [theme.breakpoints.up('md')]: {
      gridColumn: '1 / span 4',
      width: '160px',
      '& svg': {
        width: '160px'
      }
    }
  },
  logoLink: {
    minHeight: 42,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1)
  },
  logoSvg: {
    color: theme.colors.text,
    '&.home': {
      transform: `translate(calc((${span(1, 'md', theme.gutter.sm * 2, false)}) + ${theme.gutter.sm}px), 0)`
    },
    [theme.breakpoints.up('md')]: {
      '&.home': {
        transform: `translate(calc((${span(3, 'md', theme.gutter.md * 2, false)}) + ${theme.gutter.md}px), 0)`
      }
    }
  },
  rhs: {
    display: 'block',
    width: span(5, 'sm', theme.gutter.sm * 2),
    position: 'absolute',
    right: 0,
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      width: span(8, 'md', theme.gutter.md * 2)
    }
  }
})

export default Header
