import React, { useRef, useCallback } from 'react'
import cn from 'classnames'
import ResponsiveImage from '../../ResponsiveImage'
import { theme } from '../../../styles/theme'
import { useHoverEvents } from '../../../hooks/useHover'
import gsap from 'gsap'
import { createUseStyles } from '../../../helpers/createStyles'
import { primaryInput } from 'detect-it'
import Placeholder from '../../Placeholder'
import useScrollTrigger from '../../../hooks/useScrollTrigger'

const CustomColorTile = (props) => {
  const {
    tileIndex,
    className,
    product,
    aspect,
    mobileAspect,
    showPreviewImage = true,
    placeholder,
    animateIn,
    label,
    topShot,
    featureImage,
    colorSwatch
  } = props
  const styles = useStyles()
  const featureImageRef = useRef()

  const tileRef = animateIn ? useScrollTrigger(() => ({
    trigger: tileRef.current,
    start: () => 'top bottom',
    scrub: false
  }),
  (tl, ref) => {
    tl.from(ref.current, { y: 40, opacity: 0, delay: 0.2 * (tileIndex % 4), ease: 'expo.out', duration: 1.5 })
  }) : useRef()

  const resolvedImage = colorSwatch && colorSwatch.asset ? colorSwatch : topShot
  const layoutAspect = 563 / 752
  const desktopAspect = aspect || layoutAspect
  const ref = useHoverEvents(primaryInput !== 'touch', useCallback((hovering) => {
    if (featureImageRef.current) {
      gsap.to(featureImageRef.current, { opacity: hovering ? 1 : 0, duration: 0.15, ease: 'sine.inOut' })
    }
  }, []))

  if (placeholder) {
    return (
      <article className={className}>
        <div className={styles.link}>
          <Placeholder type='image' aspect={desktopAspect} mobileAspect={mobileAspect} />
          <Placeholder type='text' multiline={2} />
        </div>
      </article>
    )
  }

  return (
    <article className={className} ref={tileRef}>
      <a href={'/shop-all/' + product?.slug + '?color=' + label} className={styles.link} ref={ref}>
        {resolvedImage && (
          <ResponsiveImage
            className={cn(styles.image, { showBackgroundColor: !showPreviewImage })}
            image={resolvedImage}
            aspect={desktopAspect}
            mobileAspect={mobileAspect}
            showPreview={showPreviewImage}
            loading={'eager'}
          >
            {featureImage && (
              <ResponsiveImage
                className={cn(styles.featureImage)}
                image={featureImage}
                aspect={desktopAspect}
                ref={featureImageRef}
                showPreview={showPreviewImage}
                background='transparent'
                loading={'eager'}
              />
            )}
          </ResponsiveImage>
        )}
        {!resolvedImage && <Placeholder type='image' aspect={desktopAspect} mobileAspect={mobileAspect} blink={false} />}
        <span className={styles.title}>
          <span className={styles.titleText}>{product?.title}</span> <span className={styles.variant}>{label}</span>
        </span>
      </a>
    </article>
  )
}

const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
    display: 'block'
  },
  image: {
    marginBottom: theme.spacingPx(1),
    '&.showBackgroundColor': {
      backgroundColor: theme.colors.imageBackground
    }
  },
  meta: {
    marginBottom: theme.spacingPx(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacingPx(2)
    }
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    fontFamily: theme.fonts.heading,
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.02em',
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
      fontSize: '18px'
    }
  },
  titleText: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1)
    }
  },
  variant: {
    display: 'block',
    opacity: 0.5,
    [theme.breakpoints.up('md')]: {
      display: 'inline-block'
    }
  },
  featureImage: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    backgroundColor: theme.colors.imageBackground
  },
  saleBadge: {
    position: 'absolute',
    left: 10,
    top: 10,
    padding: '0 8px',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '26px',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '0.12em',
    color: '#566160',
    backgroundColor: '#fafcf8',
    zIndex: 2,
    [theme.breakpoints.up('md')]: {
      top: 16,
      left: 16,
      lineHeight: '30px'
    }
  }
})

export default CustomColorTile
